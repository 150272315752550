import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Play, Pause, SkipForward, SkipBack } from "lucide-react";

const theme = createTheme({
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h4: {
      fontSize: "2rem",
      fontWeight: 700,
      color: "#ffffff",
      marginBottom: "1rem",
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      color: "#e0e0e0",
    },
    caption: {
      fontSize: "0.875rem",
      color: "#bbbbbb",
    },
  },
  palette: {
    primary: {
      main: "#6c5ce7",
    },
    secondary: {
      main: "#ff7675",
    },
  },
});

const LaunchContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  background: "linear-gradient(135deg, #2d3436 0%, #000000 100%)",
  borderRadius: "24px",
  boxShadow: "0 12px 40px rgba(0, 0, 0, 0.15)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  color: "#ffffff",
  maxWidth: "600px",
  margin: "0 auto",
  overflow: "hidden",
  position: "relative",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background:
      "radial-gradient(circle at 30% 30%, rgba(108, 92, 231, 0.1) 0%, rgba(0, 0, 0, 0) 70%)",
    pointerEvents: "none",
  },
}));

const AlbumCover = styled("img")(({ theme }) => ({
  width: "100%",
  maxWidth: "350px",
  borderRadius: "16px",
  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)",
  marginBottom: theme.spacing(3),
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

const CountdownContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

const CountdownItem = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(1, 2),
  backgroundColor: "rgba(255, 255, 255, 0.1)",
  borderRadius: "12px",
  minWidth: "60px",
}));

const CountdownValue = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: 700,
  color: theme.palette.secondary.main,
}));

const CountdownLabel = styled(Typography)(({ theme }) => ({
  fontSize: "0.75rem",
  color: "#bbbbbb",
  textTransform: "uppercase",
}));

const PlayerControls = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(2),
  marginTop: theme.spacing(3),
}));

const ProximoLanzamiento = () => {
  const launchDate = new Date("2024-09-13T12:00:00").getTime();
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const countdownTimer = setInterval(() => {
      const now = new Date().getTime();
      const distance = launchDate - now;

      setCountdown({
        days: Math.floor(distance / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((distance % (1000 * 60)) / 1000),
      });
    }, 1000);

    return () => clearInterval(countdownTimer);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <LaunchContainer>
        <AlbumCover
          src="https://firebasestorage.googleapis.com/v0/b/lacashora-ae543.appspot.com/o/CASHORA-SINGLE-FINAL.png?alt=media&token=8f2e1505-53e5-481c-88d7-00159afb4fcd"
          alt="Próximo Álbum"
        />
        <Typography variant="h4">Próximo Lanzamiento</Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          Viernes 13 de Septiembre, 2024
        </Typography>

        <CountdownContainer>
          {Object.entries(countdown).map(([unit, value]) => (
            <CountdownItem key={unit}>
              <CountdownValue>{value}</CountdownValue>
              <CountdownLabel>{unit}</CountdownLabel>
            </CountdownItem>
          ))}
        </CountdownContainer>

        <PlayerControls>
          <IconButton onClick={() => console.log("Previous track")}>
            <SkipBack color="#ffffff" />
          </IconButton>
          <IconButton onClick={() => setIsPlaying(!isPlaying)}>
            {isPlaying ? (
              <Pause color="#ffffff" size={32} />
            ) : (
              <Play color="#ffffff" size={32} />
            )}
          </IconButton>
          <IconButton onClick={() => console.log("Next track")}>
            <SkipForward color="#ffffff" />
          </IconButton>
        </PlayerControls>
      </LaunchContainer>
    </ThemeProvider>
  );
};

export default ProximoLanzamiento;
