import React, { useState, useEffect, useRef, useCallback } from "react";
import { Container, Typography, CircularProgress, Box } from "@mui/material";
import {
  getFirestore,
  collection,
  query,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { getDownloadURL, ref } from "@firebase/storage";
import { storage } from "../../firebase";
import Playlist from "./Playlist";
import Player from "./Player";

const OnlineRadio = () => {
  const [songs, setSongs] = useState([]);
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(50);
  const [muted, setMuted] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [lapsUpdated, setLapsUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const audioRef = useRef(new Audio());
  const firestore = getFirestore();

  // Función para cargar la canción
  const loadSong = useCallback(
    async (index) => {
      setIsLoading(true);
      const songToPlay = songs[index];

      if (!songToPlay || !songToPlay.url || !songToPlay.id) {
        console.error("La canción no está disponible.");
        setIsLoading(false);
        return;
      }

      audioRef.current.src = songToPlay.url;
      audioRef.current.load();
      audioRef.current.volume = volume / 100;
      audioRef.current.muted = muted;

      audioRef.current.onloadedmetadata = () => {
        setDuration(audioRef.current.duration);
        setIsLoading(false);
        if (isPlaying) {
          audioRef.current.play().catch(console.error);
        }
      };

      audioRef.current.ontimeupdate = () => {
        setProgress(audioRef.current.currentTime);
        if (!lapsUpdated && audioRef.current.currentTime > 1) {
          updateLaps(songToPlay.id, songToPlay.laps);
          setLapsUpdated(true);
        }
      };

      audioRef.current.onended = handleNext;
    },
    [songs, volume, muted, isPlaying, lapsUpdated]
  );

  const updateLaps = async (songId, currentLaps) => {
    const firestore = getFirestore();
    try {
      await firestore.doc(`songs/${songId}`).update({
        laps: currentLaps + 1,
      });
    } catch (error) {
      console.error("Error al actualizar las vueltas de la canción:", error);
    }
  };

  // Cargar canciones desde Firestore
  useEffect(() => {
    const songsRef = collection(firestore, "songs");
    const q = query(songsRef, orderBy("timestamp", "asc"));
    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const fetchedSongs = await Promise.all(
        snapshot.docs.map(async (doc) => {
          const songData = doc.data();
          const url = await getDownloadURL(ref(storage, songData.storagePath));
          return {
            id: doc.id,
            title: songData.title,
            artist: songData.artist,
            coverURL: songData.coverURL,
            url,
            laps: songData.laps || 0,
          };
        })
      );
      setSongs(fetchedSongs);
    });

    return () => unsubscribe();
  }, [firestore]);

  // Cargar canción al cambiar índice o canciones
  useEffect(() => {
    if (songs.length > 0) {
      loadSong(currentSongIndex);
    }
  }, [currentSongIndex, songs, loadSong]);

  // Controlar reproducción/pausa
  const handlePlayPause = () => {
    if (!audioRef.current) return;

    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play().catch(console.error);
    }
    setIsPlaying(!isPlaying);
  };

  // Pasar a la siguiente canción
  const handleNext = useCallback(() => {
    const nextIndex = (currentSongIndex + 1) % songs.length;
    setCurrentSongIndex(nextIndex);
    setIsPlaying(true);
  }, [currentSongIndex, songs.length]);

  // Volver a la canción anterior
  const handlePrevious = () => {
    const prevIndex = (currentSongIndex - 1 + songs.length) % songs.length;
    setCurrentSongIndex(prevIndex);
    setIsPlaying(true);
  };

  // Cambiar el volumen
  const handleVolumeChange = (newValue) => {
    setVolume(newValue);
    if (audioRef.current) {
      audioRef.current.volume = newValue / 100;
    }
  };

  // Mute/desmute el audio
  const handleMute = () => {
    setMuted(!muted);
    if (audioRef.current) {
      audioRef.current.muted = !muted;
    }
  };

  // Cambiar el progreso de la canción
  const handleProgressChange = (newValue) => {
    if (audioRef.current) {
      audioRef.current.currentTime = newValue;
      setProgress(newValue);
    }
  };

  // Seleccionar canción desde Playlist
  const selectSongFromPlaylist = (index) => {
    setCurrentSongIndex(index);
    setIsPlaying(true);
  };

  return (
    <Container maxWidth="sm" sx={{ pt: 2, pb: 4 }}>
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        sx={{ color: "#f66044", fontWeight: "bold" }}
      >
        Community Radio
      </Typography>

      <Playlist
        songs={songs}
        currentSongIndex={currentSongIndex}
        selectSong={selectSongFromPlaylist}
      />

      {songs.length === 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress sx={{ color: "#f66044" }} />
        </Box>
      )}

      <Player
        song={songs[currentSongIndex]}
        isPlaying={isPlaying}
        progress={progress}
        duration={duration}
        isLoading={isLoading}
        handlePlayPause={handlePlayPause}
        handleNext={handleNext}
        handlePrevious={handlePrevious}
        handleVolumeChange={handleVolumeChange}
        handleMute={handleMute}
        handleProgressChange={handleProgressChange}
        volume={volume}
        muted={muted}
      />
    </Container>
  );
};

export default OnlineRadio;
