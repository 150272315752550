import React from "react";
import AllProducts from "./AllProducts"; // Asegúrate de que la ruta esté correcta

const Dashboard = () => {
  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h1 style={{ textAlign: "center", marginBottom: "20px", color: "#333" }}>
        Dashboard de Productos
      </h1>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <AllProducts />
      </div>
      {/* Aquí puedes agregar más componentes o estadísticas en tu dashboard */}
    </div>
  );
};

export default Dashboard;
