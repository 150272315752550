import React, { useState, useEffect } from "react";
import { db } from "./firebase";
import { collection, getDocs } from "firebase/firestore";

const AllProducts = () => {
  const [productCount, setProductCount] = useState(0);

  useEffect(() => {
    const fetchProductCount = async () => {
      try {
        const productsSnapshot = await getDocs(collection(db, "products"));
        setProductCount(productsSnapshot.size);
      } catch (error) {
        console.error("Error obteniendo la cantidad de productos: ", error);
      }
    };

    fetchProductCount();
  }, []);

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <h2 style={{ color: "#333" }}>Cantidad Total de Productos</h2>
      <p style={{ fontSize: "24px", fontWeight: "bold" }}>{productCount}</p>
    </div>
  );
};

export default AllProducts;
