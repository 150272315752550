import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Slider,
  Card,
  CardMedia,
  CircularProgress,
  SwipeableDrawer,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Player = ({
  song,
  isPlaying,
  progress,
  duration,
  isLoading,
  handlePlayPause,
  handleNext,
  handlePrevious,
  handleVolumeChange,
  handleMute,
  handleProgressChange,
  volume,
  muted,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const MiniPlayer = () => (
    <Card
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        height: "70px",
        backgroundColor: "#1f1f1f",
        color: "#fff",
        display: "flex",
        alignItems: "center",
        padding: "0 8px",
        zIndex: 1000,
        cursor: "pointer", // Cursor pointer para indicar que es clickable
      }}
      onClick={() => setIsExpanded(true)} // Expande el reproductor al hacer clic
    >
      <CardMedia
        component="img"
        alt={song?.title}
        image={song?.coverURL}
        sx={{
          width: 50,
          height: 50,
          borderRadius: "4px",
          margin: "0 8px",
        }}
      />
      <Box sx={{ flexGrow: 1, overflow: "hidden", mr: 1 }}>
        <Typography noWrap variant="subtitle2">
          {song?.title}
        </Typography>
        <Typography noWrap variant="caption" sx={{ color: "#f66044" }}>
          {song?.artist}
        </Typography>
      </Box>
      <IconButton
        onClick={(e) => {
          e.stopPropagation(); // Evita que el click en el botón expanda el reproductor
          handlePrevious();
        }}
        sx={{ color: "#f66044" }}
      >
        <SkipPreviousIcon />
      </IconButton>
      <IconButton
        onClick={(e) => {
          e.stopPropagation(); // Evita que el click en el botón expanda el reproductor
          handlePlayPause();
        }}
        sx={{ color: "#f66044" }}
      >
        {isLoading ? (
          <CircularProgress size={24} sx={{ color: "#f66044" }} />
        ) : isPlaying ? (
          <PauseIcon />
        ) : (
          <PlayArrowIcon />
        )}
      </IconButton>
      <IconButton
        onClick={(e) => {
          e.stopPropagation(); // Evita que el click en el botón expanda el reproductor
          handleNext();
        }}
        sx={{ color: "#f66044" }}
      >
        <SkipNextIcon />
      </IconButton>
    </Card>
  );

  const ExpandedPlayer = () => (
    <SwipeableDrawer
      anchor="bottom"
      open={isExpanded}
      onClose={() => setIsExpanded(false)}
      onOpen={() => setIsExpanded(true)}
      disableSwipeToOpen={false}
      PaperProps={{
        sx: {
          height: "100%",
          backgroundColor: "#1f1f1f",
          color: "#fff",
        },
      }}
    >
      <Box
        sx={{ p: 2, height: "100%", display: "flex", flexDirection: "column" }}
      >
        <IconButton
          onClick={() => setIsExpanded(false)}
          sx={{ alignSelf: "flex-start", color: "#f66044", mb: 2 }}
        >
          <ExpandLessIcon />
        </IconButton>
        <CardMedia
          component="img"
          alt={song?.title}
          image={song?.coverURL}
          sx={{
            width: "100%",
            aspectRatio: "1/1",
            objectFit: "cover",
            borderRadius: "8px",
            mb: 4,
          }}
        />
        <Typography variant="h5" align="center" sx={{ fontWeight: 600, mb: 1 }}>
          {song?.title}
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          sx={{ mb: 4, color: "#f66044" }}
        >
          {song?.artist}
        </Typography>

        <Box sx={{ mb: 4, width: "100%" }}>
          <Slider
            value={progress}
            max={duration}
            onChange={(_, value) => handleProgressChange(value)}
            aria-labelledby="progress-slider"
            sx={{
              color: "#f66044",
              height: 4,
              "& .MuiSlider-thumb": {
                width: 12,
                height: 12,
                transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
                "&:before": {
                  boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
                },
                "&:hover, &.Mui-focusVisible": {
                  boxShadow: `0px 0px 0px 8px rgb(246 96 68 / 16%)`,
                },
                "&.Mui-active": {
                  width: 20,
                  height: 20,
                },
              },
              "& .MuiSlider-rail": {
                opacity: 0.28,
              },
            }}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
            <Typography variant="caption" sx={{ color: "#f66044" }}>
              {formatTime(progress)}
            </Typography>
            <Typography variant="caption" sx={{ color: "#f66044" }}>
              {formatTime(duration)}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-around", mb: 4 }}>
          <IconButton onClick={handlePrevious} sx={{ color: "#f66044" }}>
            <SkipPreviousIcon sx={{ fontSize: 48 }} />
          </IconButton>
          <IconButton onClick={handlePlayPause} sx={{ color: "#f66044" }}>
            {isLoading ? (
              <CircularProgress size={64} sx={{ color: "#f66044" }} />
            ) : isPlaying ? (
              <PauseIcon sx={{ fontSize: 64 }} />
            ) : (
              <PlayArrowIcon sx={{ fontSize: 64 }} />
            )}
          </IconButton>
          <IconButton onClick={handleNext} sx={{ color: "#f66044" }}>
            <SkipNextIcon sx={{ fontSize: 48 }} />
          </IconButton>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton onClick={handleMute} sx={{ color: "#f66044" }}>
            {muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
          </IconButton>
          <Slider
            value={volume}
            onChange={(_, value) => handleVolumeChange(value)}
            aria-labelledby="volume-slider"
            min={0}
            max={100}
            sx={{
              width: 200,
              color: "#f66044",
              marginLeft: 2,
              "& .MuiSlider-thumb": {
                width: 12,
                height: 12,
              },
            }}
          />
        </Box>
      </Box>
    </SwipeableDrawer>
  );

  return (
    <>
      <MiniPlayer />
      <ExpandedPlayer />
    </>
  );
};

export default Player;
