import React, { useState, useEffect } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  Pagination,
  CircularProgress,
} from "@mui/material";
import debounce from "lodash.debounce"; // Debes instalar esta librería

const ProductList = () => {
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 10;

  useEffect(() => {
    const loadProducts = async () => {
      if (!searchTerm) return;

      const functions = getFunctions();
      const searchProducts = httpsCallable(functions, "searchProducts");

      setLoading(true);
      try {
        const response = await searchProducts({
          searchTerm,
          currentPage,
          itemsPerPage,
        });
        setFilteredProducts(
          response.data.products.map((product) => ({
            id: product.id,
            Título: product.Título || "Sin Título",
            Modelo: product.Modelo || "Sin Modelo",
          }))
        );
        setTotalItems(response.data.totalItems);
      } catch (error) {
        console.error("Error fetching products: ", error);
      } finally {
        setLoading(false);
      }
    };

    // Utilizar debounce para evitar solicitudes excesivas
    const debouncedLoadProducts = debounce(loadProducts, 300);
    debouncedLoadProducts();

    return () => {
      debouncedLoadProducts.cancel();
    };
  }, [searchTerm, currentPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
  };

  return (
    <div style={{ padding: 20 }}>
      <form onSubmit={handleSearch}>
        <TextField
          fullWidth
          label="Buscar por Título o Modelo"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginBottom: 20 }}
        />
        <Button type="submit" variant="contained" color="primary">
          Buscar
        </Button>
      </form>
      {loading ? (
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Grid container spacing={2}>
          {filteredProducts.map((product) => (
            <Grid item xs={12} sm={6} md={4} key={product.id}>
              <Card>
                <CardContent>
                  <Typography variant="h5" component="div">
                    {product.Título}
                  </Typography>
                  <Typography color="text.secondary">
                    Modelo: {product.Modelo}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
      {totalItems > itemsPerPage && (
        <Pagination
          count={Math.ceil(totalItems / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          showFirstButton
          showLastButton
          style={{ marginTop: 20, display: "flex", justifyContent: "center" }}
        />
      )}
    </div>
  );
};

export default ProductList;
