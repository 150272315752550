import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  CircularProgress,
} from "@mui/material";
import {
  getFirestore,
  collection,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase"; // Importa tu configuración de Firebase

const UploadMusic = () => {
  const [title, setTitle] = useState("");
  const [artist, setArtist] = useState("");
  const [audioFile, setAudioFile] = useState(null);
  const [coverFile, setCoverFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    setAudioFile(e.target.files[0]);
  };

  const handleCoverChange = (e) => {
    setCoverFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!title || !artist || !audioFile || !coverFile) {
      alert("Por favor completa todos los campos.");
      return;
    }

    setLoading(true);

    try {
      // Subir archivo de música
      const audioFileName = `${Date.now()}_${audioFile.name}`;
      const audioStorageRef = ref(storage, `music/${audioFileName}`);
      const audioUploadTask = uploadBytesResumable(audioStorageRef, audioFile);

      audioUploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Error al subir archivo de música:", error);
          setLoading(false);
        },
        async () => {
          const audioDownloadURL = await getDownloadURL(
            audioUploadTask.snapshot.ref
          );

          // Subir archivo de portada
          const coverFileName = `${Date.now()}_${coverFile.name}`;
          const coverStorageRef = ref(storage, `covers/${coverFileName}`);
          const coverUploadTask = uploadBytesResumable(
            coverStorageRef,
            coverFile
          );

          coverUploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setUploadProgress(progress);
            },
            (error) => {
              console.error("Error al subir archivo de portada:", error);
              setLoading(false);
            },
            async () => {
              const coverDownloadURL = await getDownloadURL(
                coverUploadTask.snapshot.ref
              );

              // Guardar en Firestore
              const firestore = getFirestore();
              const songsCollectionRef = collection(firestore, "songs");

              await addDoc(songsCollectionRef, {
                title,
                artist,
                audioURL: audioDownloadURL,
                coverURL: coverDownloadURL,
                storagePath: `music/${audioFileName}`,
                timestamp: serverTimestamp(),
              });

              setLoading(false);
              setTitle("");
              setArtist("");
              setAudioFile(null);
              setCoverFile(null);
              setUploadProgress(0);
              alert("¡Canción y portada subidas exitosamente!");
            }
          );
        }
      );
    } catch (error) {
      console.error("Error al subir los archivos:", error);
      setLoading(false);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Subir Música
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Título de la canción"
            variant="outlined"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Artista"
            variant="outlined"
            fullWidth
            value={artist}
            onChange={(e) => setArtist(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <input type="file" accept="audio/*" onChange={handleFileChange} />
          <Typography variant="caption">Subir archivo de audio</Typography>
        </Grid>
        <Grid item xs={12}>
          <input type="file" accept="image/*" onChange={handleCoverChange} />
          <Typography variant="caption">Subir portada (imagen)</Typography>
        </Grid>
        {uploadProgress > 0 && (
          <Grid item xs={12}>
            <CircularProgress variant="determinate" value={uploadProgress} />
            <Typography>{`Progreso: ${Math.round(
              uploadProgress
            )}%`}</Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
            disabled={loading}
          >
            {loading ? "Subiendo..." : "Subir Canción"}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UploadMusic;
