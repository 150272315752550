import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";

const Playlist = ({ songs, currentSongIndex, selectSong }) => {
  return (
    <Box
      mt={4}
      sx={{ backgroundColor: "#1E1E1E", padding: "20px", borderRadius: "12px" }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{ color: "#FF4081", mb: 3, fontWeight: "bold" }}
      >
        Lista de canciones
      </Typography>
      <List sx={{ maxHeight: "70vh", overflowY: "auto" }}>
        {songs.map((song, index) => (
          <ListItem
            key={song.id}
            selected={index === currentSongIndex}
            sx={{
              backgroundColor:
                index === currentSongIndex ? "#FF4081" : "#2A2A2A",
              color: index === currentSongIndex ? "#FFFFFF" : "#E0E0E0",
              borderRadius: "8px",
              marginBottom: "10px",
              padding: "16px",
              transition: "all 0.3s ease",
              "&:hover": {
                backgroundColor:
                  index === currentSongIndex ? "#FF6699" : "#3A3A3A",
                transform: "translateY(-2px)",
                boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
              },
            }}
            onClick={() => selectSong(index)}
            button
          >
            <ListItemText
              primary={
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                    color: index === currentSongIndex ? "#FFFFFF" : "#E0E0E0",
                    fontSize: "1.1rem",
                  }}
                >
                  {song.title}
                </Typography>
              }
              secondary={
                <Typography
                  variant="body2"
                  sx={{
                    color: index === currentSongIndex ? "#FFFFFF" : "#BDBDBD",
                    fontSize: "0.9rem",
                  }}
                >
                  {`${song.artist} - Vueltas: ${song.laps}`}
                </Typography>
              }
            />
            <IconButton
              sx={{
                color: index === currentSongIndex ? "#FFFFFF" : "#FF4081",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                },
              }}
            >
              {index === currentSongIndex ? <PauseIcon /> : <PlayArrowIcon />}
            </IconButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default Playlist;
