import React from "react";
import { Box } from "@mui/material";

import Hero from "./Home/Hero";
import Features from "./Home/Features";
import About from "./Home/About";
import Contact from "./Home/Contact";
import Footer from "./Footer";
import PartnerSlider from "./Home/Partners";
import Chat from "./Chat";
import LocationRS from "./Home/LocationRS";
import MyMapComponent from "./Home/Coverage";
import SpotifyEmbed from "./Home/Spotify";
import Timeline from "./Home/Timeline";
import ProximoLanzamiento from "./Home/ProximoLanzamiento";

const Home = () => {
  return (
    <Box
      className="home"
      sx={{
        backgroundColor: "#282828", // Aplicar el fondo #282828
        color: "#ffffff", // Texto blanco para mejor contraste
        minHeight: "100vh", // Asegurarse de que cubra toda la altura de la ventana
      }}
    >
      <Hero />
      <ProximoLanzamiento />
      <SpotifyEmbed />
      <Timeline />

      <About />
      <Contact />
      <Chat />
    </Box>
  );
};

export default Home;
