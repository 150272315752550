import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

const FooterContainer = styled(Box)`
  background-color: #f66044;
  color: black;
  padding: 1rem 2rem;
  text-align: center;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <Typography>
        &copy; 2024 Lacashora.com // Todos los derechos reservados.
      </Typography>
    </FooterContainer>
  );
};

export default Footer;
