import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Button,
  Avatar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AccountCircle, Menu as MenuIcon } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useUserAuth } from "./context/userAuthContext";

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { user } = useUserAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleAvatarClick = () => {
    if (user) {
      navigate("/home");
    } else {
      navigate("/login");
    }
  };

  const menuItems = [
    { text: "Inicio", path: "/" },
    { text: "Radio", path: "/radio" },
    { text: "Booking", path: "/" },
    { text: "Tienda", path: "/" },
    { text: "Contacto", path: "/contacto" },
  ];

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "#362b97", minHeight: "48px" }}
    >
      <Toolbar
        sx={{ justifyContent: "space-between", padding: 1, minHeight: "48px" }}
      >
        {isMobile ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              size="large"
              sx={{ color: "#ffffff" }}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexGrow: 1,
                justifyContent: "center",
              }}
            >
              <Link to="/">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/lacashora-ae543.appspot.com/o/logo%20album.png?alt=media&token=22937a4a-c4ab-4fd5-ac8b-33ab2b7caafc"
                  alt="Logo"
                  style={{ height: "84px" }}
                />
              </Link>
            </Box>
            <IconButton
              color="inherit"
              aria-label="user"
              size="large"
              onClick={handleAvatarClick}
              sx={{ color: "white" }}
            >
              {user && user.photoURL ? (
                <Avatar alt={user.displayName} src={user.photoURL} />
              ) : (
                <AccountCircle fontSize="large" />
              )}
            </IconButton>
          </>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Link to="/">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/lacashora-ae543.appspot.com/o/logo%20album.png?alt=media&token=22937a4a-c4ab-4fd5-ac8b-33ab2b7caafc"
                  alt="Logo"
                  style={{ height: "70px" }}
                />
              </Link>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexGrow: 1,
                justifyContent: "center",
              }}
            >
              {menuItems.map((item, index) => (
                <Button
                  key={index}
                  component={Link}
                  to={item.path}
                  color="inherit"
                  sx={{
                    mx: 1,
                    fontSize: "1.1rem",
                    color: "#f25730",
                    fontWeight: "bold", // Hacer las letras más gruesas
                  }}
                >
                  {item.text}
                </Button>
              ))}
            </Box>
            <IconButton
              color="inherit"
              aria-label="user"
              size="large"
              onClick={handleAvatarClick}
              sx={{ color: "#ffffff" }}
            >
              {user && user.photoURL ? (
                <Avatar alt={user.displayName} src={user.photoURL} />
              ) : (
                <AccountCircle fontSize="large" />
              )}
            </IconButton>
          </>
        )}
      </Toolbar>

      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <List sx={{ width: 250, backgroundColor: "#f25730", height: "100%" }}>
          {menuItems.map((item, index) => (
            <ListItem
              button
              key={index}
              component={Link}
              to={item.path}
              onClick={toggleDrawer(false)}
            >
              <ListItemText
                primary={item.text}
                sx={{
                  textAlign: "center",
                  color: "#ffffff",
                  fontWeight: "bold", // Hacer las letras más gruesas
                }}
              />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </AppBar>
  );
};

export default Navbar;
