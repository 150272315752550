import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { CssBaseline, Box } from "@mui/material";
import { styled } from "@mui/system";

import Navbar from "./Navbar";
import SuccessPage from "./pages/SuccessPage";
import Home from "./Home";
import AddStore from "./Store/AddStore";
import Login from "./Components/Login";
import Signup from "./Components/Signup";
import ProductImport from "./ProductImport";
import UserDashboard from "./Components/Dashboard/UserDashboard";

import { UserAuthContextProvider } from "./context/userAuthContext";
import Footer from "./Footer";
import { Chat } from "@mui/icons-material";
import AddProject from "./Components/Projects/AddProject";
import ProjectDashboard from "./Components/Projects/ProjectDashboard";
import MyProjects from "./Components/Projects/MyProjects";
import Contact from "./Home/Contact";
import ProductDetails from "./ProductDetails";
import ProductList from "./ProductList";
import Cart from "./Cart.js";
import Dashboard from "./Dashboard.js";
import Radios from "../src/pages/Radiocomunicacion/Radios.js";
import RadiosConvencionales from "./pages/Radiocomunicacion/RadiosConvencionales.js";
import Algoliaimport from "./Algoliaimport.js";
import NuevoInventario from "./Components/Inventario/NuevoInventario.js";
import OnlineRadio from "./Components/Radio/OnlineRadio.js";
import UploadMusic from "./Components/Radio/UploadMusic.js";

const AppContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
});

const ContentContainer = styled(Box)({
  flex: 1,
});

export default function App() {
  return (
    <Router>
      <UserAuthContextProvider>
        <CssBaseline />
        <AppContainer>
          <Navbar />
          <ContentContainer>
            <Routes>
              <Route
                path="/radiosconvencionales"
                element={<RadiosConvencionales />}
              />
              <Route path="/nuevoinventario" element={<NuevoInventario />} />
              <Route path="/algolia" element={<Algoliaimport />} />
              <Route path="/radio" element={<OnlineRadio />} />
              <Route path="/uploadmusic" element={<UploadMusic />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/carrito" element={<Cart />} />
              <Route path="/search" element={<ProductList />} />
              <Route path="/contacto" element={<Contact />} />
              <Route path="/projectdashboard" element={<ProjectDashboard />} />
              <Route path="/addproject/:id" element={<AddProject />} />
              <Route path="/addproject" element={<AddProject />} />
              <Route path="/success" element={<SuccessPage />} />
              <Route path="/import" element={<ProductImport />} />
              <Route path="/login" element={<Login />} />
              <Route path="/registro" element={<Signup />} />
              <Route path="/home" element={<UserDashboard />} />
              <Route path="/addstore" element={<AddStore />} />
              <Route path="/" element={<Home />} />
              <Route path="/myprojects" element={<MyProjects />} />
              <Route
                path="/producto/:documentName"
                element={<ProductDetails />}
              />
            </Routes>
          </ContentContainer>
          <Footer />
        </AppContainer>
      </UserAuthContextProvider>
    </Router>
  );
}
