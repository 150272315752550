import React from "react";
import { Box } from "@mui/material";
import { styled, keyframes } from "@mui/system";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Keyframes para la animación de rotación de tono
const hueRotation = keyframes`
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
`;

// Keyframes para el efecto de pulso
const pulse = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
`;

// Contenedor del Hero
const HeroContainer = styled(Box)`
  height: 70vh;
  position: relative;
  overflow: hidden;
`;

// Overlay para los efectos de mezcla y color
const Overlay = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    rgba(40, 40, 40, 0.6),
    rgba(238, 86, 49, 0.6)
  );
  mix-blend-mode: screen;
  animation: ${hueRotation} 10s linear infinite;
`;

// Estilización del slider
const StyledSlider = styled(Slider)`
  .slick-slide img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
    filter: saturate(200%) contrast(150%) brightness(90%);
    animation: ${pulse} 6s infinite;
    transition: filter 0.5s ease-in-out;

    &:hover {
      filter: saturate(250%) contrast(200%) brightness(120%);
    }
  }

  .slick-dots {
    bottom: 15px;
    li button:before {
      color: #ee5631;
    }
  }
`;

const Hero = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
  };

  return (
    <HeroContainer id="hero">
      <StyledSlider {...settings}>
        <div>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/lacashora-ae543.appspot.com/o/LaCashora-04.jpg?alt=media&token=26d42915-067e-4034-b1d4-5979f9c4f024"
            alt="Slide 1"
          />
        </div>
        <div>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/lacashora-ae543.appspot.com/o/311463834_1879535632383497_4269619849822698246_n.jpg?alt=media&token=08d286eb-2c41-46e7-a925-70b54d10b0c3"
            alt="Slide 2"
          />
        </div>
        <div>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/lacashora-ae543.appspot.com/o/311600849_420329856920122_8123882914929686394_n.jpg?alt=media&token=f7a14775-dd1a-4042-ad81-e0ea6b70ec70"
            alt="Slide 3"
          />
        </div>
      </StyledSlider>
      <Overlay />
    </HeroContainer>
  );
};

export default Hero;
