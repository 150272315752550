import React, { useState } from "react";
import { db, storage } from "../../firebase"; // Importa Firestore y Storage desde firebase.js
import { collection, doc, setDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  IconButton,
  Grid,
  Paper,
} from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import DeleteIcon from "@mui/icons-material/Delete";
import QrScanner from "react-qr-scanner";
import NuevaCategoria from "./NuevaCategoria";

const Inventario = () => {
  const [titulo, setTitulo] = useState("");
  const [marca, setMarca] = useState("");
  const [numInventario, setNumInventario] = useState("");
  const [imagenes, setImagenes] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [message, setMessage] = useState("");
  const [showQrScanner, setShowQrScanner] = useState(false);
  const [uploading, setUploading] = useState(false);

  const handleAddProduct = async (e) => {
    e.preventDefault();

    try {
      // Crear un documento con un ID aleatorio generado por Firebase
      const newDocRef = doc(collection(db, "inventory"));

      await setDoc(newDocRef, {
        titulo,
        marca,
        numInventario,
        imagenes,
      });

      setMessage("Producto agregado con éxito!");
      setTitulo("");
      setMarca("");
      setNumInventario("");
      setImagenes([]);
      setPreviewUrls([]);
    } catch (error) {
      console.error("Error al agregar el producto:", error);
      setMessage("Error al agregar el producto. Intenta nuevamente.");
    }
  };

  const handleScan = (data) => {
    if (data) {
      setNumInventario(data.text); // Usamos data.text para obtener el valor del QR
      setShowQrScanner(false);
    }
  };

  const handleError = (err) => {
    console.error("Error al escanear el código QR:", err);
    setMessage("Error al escanear el código QR. Intenta nuevamente.");
  };

  const handleImageSelect = (e) => {
    const files = Array.from(e.target.files);
    const newPreviewUrls = files.map((file) => URL.createObjectURL(file));
    setPreviewUrls((prev) => [...prev, ...newPreviewUrls]);
    setImagenes((prev) => [...prev, ...files]);
  };

  const handleImageUpload = async () => {
    if (imagenes.length === 0) return;

    setUploading(true);
    const newDocRef = doc(collection(db, "inventory"));
    const uploadedUrls = [];

    for (let i = 0; i < imagenes.length; i++) {
      const file = imagenes[i];
      const storageRef = ref(storage, `inventory/${newDocRef.id}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      try {
        await new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              // Progreso de la carga
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log(`Subiendo imagen ${i + 1}: ${progress}% hecho`);
            },
            (error) => {
              console.error("Error al subir la imagen:", error);
              reject(error);
            },
            async () => {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              uploadedUrls.push(downloadURL);
              resolve();
            }
          );
        });
      } catch (error) {
        setUploading(false);
        setMessage("Error al subir las imágenes. Intenta nuevamente.");
        return;
      }
    }

    setImagenes(uploadedUrls);
    setUploading(false);
    setMessage("Imágenes subidas con éxito!");
  };

  const handleImageDelete = (index) => {
    setPreviewUrls((prev) => prev.filter((_, i) => i !== index));
    setImagenes((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <div style={{ padding: 20 }}>
      <Card>
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom>
            Agregar Producto al Inventario
          </Typography>
          <form onSubmit={handleAddProduct}>
            <TextField
              fullWidth
              label="Título del Producto"
              variant="outlined"
              value={titulo}
              onChange={(e) => setTitulo(e.target.value)}
              style={{ marginBottom: 20 }}
              required
            />
            <TextField
              fullWidth
              label="Marca del Producto"
              variant="outlined"
              value={marca}
              onChange={(e) => setMarca(e.target.value)}
              style={{ marginBottom: 20 }}
              required
            />
            <TextField
              fullWidth
              label="Número de Inventario"
              variant="outlined"
              value={numInventario}
              onChange={(e) => setNumInventario(e.target.value)}
              style={{ marginBottom: 20 }}
              required
            />
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="upload-button"
              type="file"
              multiple
              onChange={handleImageSelect}
            />
            <label htmlFor="upload-button">
              <Button
                variant="contained"
                color="primary"
                component="span"
                startIcon={<PhotoCamera />}
                style={{ marginBottom: 20 }}
              >
                Seleccionar Imágenes
              </Button>
            </label>
            {previewUrls.length > 0 && (
              <Grid container spacing={2} style={{ marginBottom: 20 }}>
                {previewUrls.map((url, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Paper elevation={3} style={{ position: "relative" }}>
                      <img
                        src={url}
                        alt={`Preview ${index + 1}`}
                        style={{
                          width: "100%",
                          height: "200px",
                          objectFit: "cover",
                        }}
                      />
                      <IconButton
                        aria-label="delete"
                        style={{ position: "absolute", top: 10, right: 10 }}
                        onClick={() => handleImageDelete(index)}
                      >
                        <DeleteIcon style={{ color: "red" }} />
                      </IconButton>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            )}
            <Button
              variant="contained"
              color="secondary"
              onClick={handleImageUpload}
              disabled={uploading || imagenes.length === 0}
              style={{ marginBottom: 20 }}
            >
              {uploading ? "Subiendo..." : "Subir Imágenes"}
            </Button>
            <div>
              {imagenes.length > 0 &&
                typeof imagenes[0] === "string" &&
                imagenes.map((url, index) => (
                  <Typography key={index} variant="body2">
                    Imagen {index + 1}:{" "}
                    <a href={url} target="_blank" rel="noopener noreferrer">
                      {url}
                    </a>
                  </Typography>
                ))}
            </div>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setShowQrScanner(!showQrScanner)}
              style={{ marginBottom: 20 }}
            >
              {showQrScanner ? "Cerrar Escáner QR" : "Escanear Código QR"}
            </Button>
            {showQrScanner && (
              <div style={{ marginBottom: 20 }}>
                <QrScanner
                  delay={300}
                  onError={handleError}
                  onScan={handleScan}
                  style={{ width: "100%" }}
                />
              </div>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={uploading}
            >
              Agregar Producto
            </Button>
          </form>
          {message && (
            <Typography
              variant="body1"
              color="text.secondary"
              style={{ marginTop: 20 }}
            >
              {message}
            </Typography>
          )}
        </CardContent>
      </Card>
      <NuevaCategoria />
    </div>
  );
};

export default Inventario;
