import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../context/userAuthContext";
import { Link, useNavigate } from "react-router-dom";
import {
  Typography,
  Grid,
  Box,
  Avatar,
  Container,
  useTheme,
  useMediaQuery,
  Paper,
  Card,
  CardActionArea,
  CardContent,
} from "@mui/material";
import {
  ConfirmationNumber,
  Event,
  ShoppingCart,
  Support,
  ExitToApp,
  Person,
} from "@mui/icons-material";

const UserDashboard = () => {
  const { user, logOut } = useUserAuth();
  const [displayName, setDisplayName] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (user) {
      setDisplayName(user.displayName || "Usuario");
      setPhotoURL(user.photoURL || "https://via.placeholder.com/150");
    }
  }, [user]);

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const menuItems = [
    {
      icon: <ConfirmationNumber style={{ fontSize: "3rem" }} />,
      text: "Tickets",
      path: "/tickets",
    },
    {
      icon: <Event style={{ fontSize: "3rem" }} />,
      text: "Eventos",
      path: "/eventos",
    },
    {
      icon: <ShoppingCart style={{ fontSize: "3rem" }} />,
      text: "Compras",
      path: "/compras",
    },
    {
      icon: <Support style={{ fontSize: "3rem" }} />,
      text: "Soporte",
      path: "/soporte",
    },
    {
      icon: <Person style={{ fontSize: "3rem" }} />,
      text: "Perfil",
      path: "/perfil",
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mb: 4,
          }}
        >
          <Avatar
            alt={displayName}
            src={photoURL}
            sx={{ width: 120, height: 120, mb: 2 }}
          />
          <Typography variant="h4" gutterBottom>
            Bienvenido, {displayName}
          </Typography>
        </Box>

        <Grid container spacing={3} justifyContent="center">
          {menuItems.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  borderRadius: 2,
                  boxShadow: 3,
                  "&:hover": {
                    transform: "translateY(-5px)",
                    boxShadow: 5,
                  },
                  transition: "all 0.3s",
                }}
              >
                <CardActionArea
                  component={Link}
                  to={item.path}
                  sx={{
                    textAlign: "center",
                    py: 4,
                    backgroundColor: "#362b97", // Usando el color azul principal de la página
                    color: "white",
                  }}
                >
                  {item.icon}
                  <CardContent>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: isMobile ? "1rem" : "1.2rem",
                        textTransform: "none",
                      }}
                    >
                      {item.text}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                borderRadius: 2,
                boxShadow: 3,
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: 5,
                },
                transition: "all 0.3s",
              }}
            >
              <CardActionArea
                onClick={handleLogout}
                sx={{
                  textAlign: "center",
                  py: 4,
                  backgroundColor: "#f66044", // Usando el color naranja principal de la página
                  color: "white",
                }}
              >
                <ExitToApp style={{ fontSize: "3rem" }} />
                <CardContent>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: isMobile ? "1rem" : "1.2rem",
                      textTransform: "none",
                    }}
                  >
                    Cerrar Sesión
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default UserDashboard;
