import React, { useState } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import AlbumIcon from "@mui/icons-material/Album";
import EventIcon from "@mui/icons-material/Event";
import StarIcon from "@mui/icons-material/Star";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import useMediaQuery from "@mui/material/useMediaQuery";

const CustomizedTimeline = () => {
  const events = [
    {
      year: "2020",
      title: "Nace el cashoreo",
      subtitle: "Matrix Pary",
      location: "Hermosillo, Sonora",
      date: "June 2021",
      description: "Preparandonos para el primer toquin",
      media:
        "https://firebasestorage.googleapis.com/v0/b/lacashora-ae543.appspot.com/o/start.jpg?alt=media&token=d5093541-bbea-4bf4-b6d0-c208c2d7fe20",
      icon: <MusicNoteIcon />,
      background: "#362b98",
    },
    {
      year: "2020",
      title: "Matrix Pary",
      subtitle: "Matrix Pary",
      location: "Hermosillo, Sonora",
      date: "June 2021",
      description: "First performance",
      media:
        "https://firebasestorage.googleapis.com/v0/b/lacashora-ae543.appspot.com/o/start.png?alt=media&token=38d71440-228b-43e0-b521-1604720cb0b9",
      icon: <EventIcon />,
      background: "#362b98",
    },
    {
      year: "2020",
      title: "Pre Trippy Night IV",
      subtitle: "Summer Tour",
      location: "Hermosillo, Sonora",
      date: "July 31, 2021",
      description: "A series of performances during the summer.",
      media:
        "https://firebasestorage.googleapis.com/v0/b/lacashora-ae543.appspot.com/o/trippy%20night.png?alt=media&token=5d0890f0-9968-4faf-b8a7-12174db1dd9d",
      icon: <EventIcon />,
      background: "#362b98",
    },
    {
      year: "2022",
      title: "Trippy Night 2022",
      subtitle: "Album Release",
      location: "Agora. Hermosillo, Sonora, Mexico",
      date: "September 2022",
      description: "Released a new album",
      media:
        "https://firebasestorage.googleapis.com/v0/b/lacashora-ae543.appspot.com/o/trippy%20night%202.png?alt=media&token=6bd77e1d-0ad1-49b9-b8aa-44b31f4c0560",
      icon: <EventIcon />,
      background: "#362b98",
    },
    {
      year: "2022",
      title: "EER Anniversary",
      subtitle: "World Tour",
      location: "Global",
      date: "October 2022",
      description:
        "Aniversario de el label Electric Emerald Records ahora llamado G.E.M sounds",
      media:
        "https://firebasestorage.googleapis.com/v0/b/lacashora-ae543.appspot.com/o/Snapinsta.app_video_An-fHnLdf69ihwSwIfLzLBq8X1bdxAysfj0TRji6A9H8EL1B7HbQMz_2vxkDb75q7U-UlzQDrnRfbQ28iWDEgNc.mp4?alt=media&token=8b3b2282-d29c-4d95-b3d9-4c983753f854",
      icon: <EventIcon />,
      background: "#362b98",
    },
    {
      year: "2022",
      title: "La Gloriosa",
      subtitle: "Anniversary Concert",
      location: "Hermosillo, Son",
      date: "July 2022",
      description: "Celebrating 1 year of music with a special concert.",
      media:
        "https://firebasestorage.googleapis.com/v0/b/lacashora-ae543.appspot.com/o/278052383_554431972575428_5464927475070704524_n.jpeg?alt=media&token=05430c8c-687c-4e8f-970a-0184baa1e5a5",
      icon: <EventIcon />,
      background: "#362b98",
    },
    {
      year: "2022",
      title: "CDMX",
      subtitle: "Anniversary Concert",
      location: "Ciudad de Mexico, CDMX",
      date: "July 2022",
      description: "Blasting night with COZMOZ and the FlashBacks",
      media:
        "https://firebasestorage.googleapis.com/v0/b/lacashora-ae543.appspot.com/o/CDMX.png?alt=media&token=5f1a55d2-20ed-4d00-96a7-87cc0a32a9eb",
      icon: <EventIcon />,
      background: "#362b98",
    },
    {
      year: "2022",
      title: "Sahuaro Fest",
      subtitle: "Anniversary Concert",
      location: "Parque La Ruina, Hermosillo, Son",
      date: "Octubre 19, 2022",
      description: "Abriendo para Belafonte Sensacional",
      media:
        "https://firebasestorage.googleapis.com/v0/b/lacashora-ae543.appspot.com/o/312487746_202940772117011_8445737130121702959_n.jpeg?alt=media&token=b84686f2-0756-4990-9e00-9b4824c9c00a",
      icon: <EventIcon />,
      background: "#362b98",
    },
    {
      year: "2023",
      title: "Nogales Fest",
      subtitle: "Anniversary Concert",
      location: "Nogales, Sonora, Mexico",
      date: "Noviembre 14, 2022",
      description: "Gira Diles Que No Me Maten.",
      media:
        "https://firebasestorage.googleapis.com/v0/b/lacashora-ae543.appspot.com/o/Snapinsta.app_video_E74E2360A6D221769682AB5A78084AAC_video_dashinit.mp4?alt=media&token=b3f22169-6b24-45b5-ae93-c3aaa879b535",
      icon: <EventIcon />,
      background: "#362b98",
    },
    {
      year: "2022",
      title: "Grabando el primer LP en G.E.M Sounds",
      subtitle: "Anniversary Concert",
      location: "Nogales, Sonora, Mexico",
      date: "Noviembre 27, 2023",
      description: "Ingenieros de Audio: Nathan Larrinaga, David Garcia.",
      media:
        "https://firebasestorage.googleapis.com/v0/b/lacashora-ae543.appspot.com/o/317384279_1603548830076927_5496682356612488890_n.jpg?alt=media&token=de654758-9d5d-4e51-94ef-ffbd013a24cd",
      icon: <AlbumIcon />,
      background: "#362b98",
    },
  ];

  const renderMedia = (media) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const isVideo =
      media.includes(".mp4") ||
      media.includes(".webm") ||
      media.includes(".ogg") ||
      media.includes("video");

    if (isVideo) {
      return (
        <div style={{ position: "relative", width: "100%", marginTop: "10px" }}>
          {!isPlaying && (
            <button
              onClick={() => setIsPlaying(true)}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                background: "rgba(0, 0, 0, 0.5)",
                border: "none",
                borderRadius: "50%",
                padding: "10px",
                cursor: "pointer",
              }}
            >
              <PlayArrowIcon style={{ fontSize: "3rem", color: "#fff" }} />
            </button>
          )}
          <video
            controls
            autoPlay={isPlaying}
            style={{ width: "100%", borderRadius: "8px" }}
            onPlay={() => setIsPlaying(true)}
          >
            <source src={media} />
            Your browser does not support the video tag.
          </video>
        </div>
      );
    } else {
      return (
        <img
          src={media}
          alt="event media"
          style={{ width: "100%", borderRadius: "8px", marginTop: "10px" }}
        />
      );
    }
  };

  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <VerticalTimeline
      lineColor="#ec5733"
      layout={isMobile ? "1-column" : "2-columns"}
      animate={false} // Desactivar la animación para mostrar todo de una vez
    >
      {events.map((event, index) => (
        <VerticalTimelineElement
          key={index}
          className="vertical-timeline-element--work"
          contentStyle={{
            background: event.background,
            color: "#fff",
            padding: isMobile ? "10px" : "20px", // Ajustar padding en móvil
          }}
          contentArrowStyle={{
            borderRight: `7px solid ${event.background}`,
          }}
          iconStyle={{ background: event.background, color: "#fff" }}
          icon={event.icon}
        >
          <h2
            style={{
              color: "#ec5733",
              fontSize: isMobile ? "1.5rem" : "1.8rem", // Cambiar tamaño de texto en móvil
              fontWeight: "bold",
              marginBottom: "15px",
            }}
          >
            {event.date}
          </h2>
          <h3
            className="vertical-timeline-element-title"
            style={{
              fontSize: isMobile ? "1.2rem" : "1.5rem", // Ajustar tamaño de título
            }}
          >
            {event.title}
          </h3>
          <h4
            className="vertical-timeline-element-subtitle"
            style={{ fontSize: isMobile ? "1rem" : "1.3rem" }}
          >
            {event.subtitle}
          </h4>
          <p
            style={{
              fontSize: isMobile ? "0.9rem" : "1rem", // Cambiar tamaño de texto en móvil
            }}
          >
            {event.description}
          </p>
          {renderMedia(event.media)}
          <p style={{ fontSize: isMobile ? "0.8rem" : "1rem" }}>
            {event.location}
          </p>
        </VerticalTimelineElement>
      ))}
      <VerticalTimelineElement
        iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
        icon={<StarIcon />}
      />
    </VerticalTimeline>
  );
};

export default CustomizedTimeline;
