import React from "react";
import { useMediaQuery, useTheme, Typography, Box } from "@mui/material";

const SpotifyEmbed = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#362b97", // Fondo color azul
        padding: "20px",
        borderRadius: "12px",
        margin: "20px 0",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", // Añadir una sombra sutil para más profundidad
      }}
    >
      <Typography
        variant="h5"
        sx={{
          color: "#f66044", // Título color naranja
          marginBottom: "20px",
          fontWeight: 700,
        }}
      >
        Discografía
      </Typography>
      <iframe
        style={{
          borderRadius: "12px",
          width: isMobile ? "100%" : "70%",
          height: isMobile ? "352px" : "200px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)", // Añadir sombra al iframe
        }}
        src="https://open.spotify.com/embed/artist/1Gxpkt1HUpUpu8WXaKIkmM?utm_source=generator&theme=0"
        frameBorder="0"
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
        title="Spotify Artist"
      ></iframe>
    </Box>
  );
};

export default SpotifyEmbed;
