import React from "react";
import { Box, Typography, Link, IconButton } from "@mui/material";
import { styled, keyframes } from "@mui/system";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InstagramIcon from "@mui/icons-material/Instagram";

const theme = createTheme({
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h2: {
      fontSize: "2.5rem",
      fontWeight: 700,
      marginBottom: "2rem",
      color: "#f66044",
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 600,
      color: "#ffffff",
    },
    subtitle1: {
      fontSize: "1.2rem",
      fontWeight: 400,
      color: "#bbbbbb",
    },
    body1: {
      fontSize: "1rem",
      fontStyle: "italic",
      fontWeight: 300,
      color: "#bbbbbb",
      marginTop: "1rem",
    },
  },
  palette: {
    primary: {
      main: "#3f37a4",
    },
    secondary: {
      main: "#f66044",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const LabelInfoContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6, 2),
  background: "#282828", // Color de fondo gris oscuro
  borderRadius: "16px",
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.5)", // Sombras más pronunciadas
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  color: "#ffffff",
  animation: `${fadeIn} 1s ease-in-out`,
  maxWidth: "500px",
  margin: "0 auto",
  border: "1px solid #444444", // Borde sutil para mejor definición
}));

const LogoBox = styled(Box)(({ theme }) => ({
  width: "180px",
  height: "180px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "15%",
  backgroundColor: "#f66044",
  boxShadow: "0 6px 12px rgba(0, 0, 0, 0.25)",
  overflow: "hidden",
  marginBottom: theme.spacing(3),
  img: {
    maxWidth: "80%", // Ajustado para mejor visualización del logo
    maxHeight: "80%",
    objectFit: "contain",
  },
}));

const PartnerSlider = () => {
  return (
    <ThemeProvider theme={theme}>
      <LabelInfoContainer>
        <LogoBox>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/lacashora-ae543.appspot.com/o/440813041_1498957060965643_7065239820280933259_n.jpg?alt=media&token=3a140876-3dbc-403f-bbf0-3df8cf38d9b5"
            alt="G.E.M Sounds Logo"
          />
        </LogoBox>
        <Link
          href="https://www.instagram.com/g.e.m.sound/"
          target="_blank"
          rel="noopener"
          underline="none"
        >
          <Typography variant="h4">Label: G.E.M Sounds</Typography>
        </Link>
        <Typography variant="subtitle1">Joined at 2022</Typography>
        <Typography variant="body1">
          Agradecemos de todo corazón a GEM Sounds por creer en nosotros y
          darnos la oportunidad de compartir nuestra música. Su apoyo ha sido un
          pilar importante en nuestro camino, y estamos profundamente
          agradecidos por todo lo que han hecho para ayudarnos a crecer.
        </Typography>
        <Box mt={2}>
          <IconButton
            href="https://www.instagram.com/g.e.m.sound/"
            target="_blank"
            rel="noopener"
            color="secondary"
            aria-label="Instagram"
          >
            <InstagramIcon fontSize="large" />
          </IconButton>
        </Box>
      </LabelInfoContainer>
    </ThemeProvider>
  );
};

export default PartnerSlider;
