import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PartnerSlider from "./Partners";

// Crear un tema con una tipografía moderna y colores consistentes
const theme = createTheme({
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h3: {
      fontSize: "2rem",
      fontWeight: 700,
      color: "#f66044",
      "@media (min-width:600px)": {
        fontSize: "2.5rem", // Aumenta el tamaño del texto en pantallas más grandes
      },
    },
    body1: {
      fontSize: "1rem",
      lineHeight: 1.6,
      color: "#ffffff",
      "@media (min-width:600px)": {
        fontSize: "1.2rem", // Aumenta el tamaño del cuerpo en pantallas más grandes
      },
    },
  },
  palette: {
    primary: {
      main: "#3f37a4",
    },
    secondary: {
      main: "#f66044",
    },
  },
});

const AboutContainer = styled(Box)(({ theme }) => ({
  padding: "2rem 1rem",
  backgroundColor: "#3f37a4",
  textAlign: "center",
  maxWidth: "1200px",
  margin: "0 auto",
  boxSizing: "border-box",
  borderRadius: "16px",
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.2)",
}));

const RoundedImage = styled("img")(({ theme }) => ({
  width: "100%",
  maxWidth: "600px",
  minWidth: "300px",
  height: "auto",
  borderRadius: "16px",
  boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
  marginBottom: "2rem",
}));

const About = () => {
  return (
    <ThemeProvider theme={theme}>
      <AboutContainer id="about">
        <RoundedImage
          src="https://firebasestorage.googleapis.com/v0/b/lacashora-ae543.appspot.com/o/356789240_292876483160766_2158144131874698038_n.jpeg?alt=media&token=b5a8da8b-ff1c-4cdc-95cf-8919ed85fd9d"
          alt="lacashora"
        />
        <Typography variant="h3" gutterBottom>
          lacashora
        </Typography>
        <Typography variant="body1" paragraph>
          Emerging from the vast expanses of the Mexican desert, La Cashora
          brings a unique blend of experimental rock infused with the deep
          grooves of krautrock and the soul of rock and roll. Their soundscape
          is a psychedelic journey, driven by ethereal synths and spacey
          guitars, creating a sonic and mesmerizing experience.
        </Typography>
        <PartnerSlider />
      </AboutContainer>
    </ThemeProvider>
  );
};

export default About;
