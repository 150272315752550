// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBdqUEI65-RMD8bKcF1eG2CT1MfLcV6wbo",
  authDomain: "lacashora-ae543.firebaseapp.com",
  projectId: "lacashora-ae543",
  storageBucket: "lacashora-ae543.appspot.com",
  messagingSenderId: "301145159063",
  appId: "1:301145159063:web:68befedaed6d4742ab40d2",
  measurementId: "G-YSDP43JL83",
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage, app as default };
